// React
import { useState, useEffect } from "react"

// DateTime
import { ms } from "@/lib/dates"

// UI
import { Odometer } from "@/components/Odometer"
// Translations
import { useTrans } from "@/i18n"

// Queries
import { useAllPlatformStatisticsTotalProductionQuery } from "@/api/graphql"

/**
 * TotalProductionCounterAllProjects
 * @returns
 */
export function TotalProductionCounterAllProjects() {
	// Config
	const timeTillRenderInSeconds = 10

	// State
	const [value, setValue] = useState(0)
	const [endDate] = useState(() => new Date().toISOString())

	const { data, isLoading } = useAllPlatformStatisticsTotalProductionQuery({
		start: "1970-01-01T00:00:00Z", // "2024-01-01T00:00:00Z",
		end: endDate, // Use the state value which is only set once
	})

	// When we have data, start the animation
	useEffect(() => {
		function work() {
			setValue((value) => {
				const currentProductionSpeed = data?.overall_statistics
					?.production_speed
					? ((parseFloat(
							data.overall_statistics.production_speed, // This is watts per 250ms?
					  ) *
							4) / // Watt/second
							1000 / // Ws > KWs
							1000) * // KWs to MWs
					  timeTillRenderInSeconds
					: 0
				return value + currentProductionSpeed
			})
		}
		// 10 seconds re-render
		const timer = setInterval(work, ms(`${timeTillRenderInSeconds}s`))
		work()
		return () => {
			clearInterval(timer)
		}
	}, [data?.overall_statistics?.production_speed, timeTillRenderInSeconds])

	// Loading state
	if (isLoading) {
		return <TotalProductionCounterAllProjectsLoading />
	}

	// Data state
	return (
		<>
			{value && value !== 0 ? (
				<Odometer
					value={value}
					format="d"
					duration={timeTillRenderInSeconds * 1000}
					auto={false}
					theme="mijnstroom"
				/>
			) : null}
		</>
	)
}

function TotalProductionCounterAllProjectsLoading() {
	const t = useTrans("common")

	return (
		<div className="flex flex-col items-center">
			<div className="rounded-2rem text-brandgray-700 flex bg-gray-100 p-4 shadow-lg">
				<div className="odometer odometer-theme-mijnstroom          odometer-animating-up odometer-animating">
					<div className="odometer-inside">
						<span className="odometer-digit">
							<span className="odometer-digit-spacer">8</span>
							<span className="odometer-digit-inner">
								<span className="odometer-ribbon">
									<span className="odometer-ribbon-inner">
										<div className="odometer-value odometer-last-value animate-pulse">
											-
										</div>
									</span>
								</span>
							</span>
						</span>
						<span className="odometer-digit">
							<span className="odometer-digit-spacer">8</span>
							<span className="odometer-digit-inner">
								<span className="odometer-ribbon">
									<span className="odometer-ribbon-inner">
										<div className="odometer-value odometer-last-value animate-pulse">
											-
										</div>
									</span>
								</span>
							</span>
						</span>
						<span className="odometer-digit">
							<span className="odometer-digit-spacer">8</span>
							<span className="odometer-digit-inner">
								<span className="odometer-ribbon">
									<span className="odometer-ribbon-inner">
										<div className="odometer-value odometer-last-value animate-pulse">
											-
										</div>
									</span>
								</span>
							</span>
						</span>
					</div>
				</div>
				<div className="flex items-center text-[22px] font-bold">
					{t(`common:common.realtime_production.graph.mwh.label`)}
				</div>
			</div>
		</div>
	)
}
