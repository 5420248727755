// React
import React from "react"

// UI
import { Card } from "@/components/Card"

/**
 * PlatformStatsDisclaimer
 *
 * @returns {React.FC}
 */
export const PlatformStatsDisclaimer: React.FC = () => {
	return (
		<Card>
			<section className="mb-8">
				<h2 className="mb-4 text-2xl font-semibold">Toelichting</h2>
				<p className="leading-relaxed">
					Deze statistieken zijn zo goed mogelijk samengesteld en
					worden eenmaal per maand vernieuwd. Er zal echter altijd een
					extra controle op alle gegevens worden gedaan bij het
					uitkeren van de ZonneRentes. De cijfers zijn indicatief: Er
					kunnen dan ook geen rechten aan worden ontleend.
				</p>
			</section>

			<section className="mb-8">
				<h2 className="mb-4 text-2xl font-semibold">
					Inzichten uit de statistieken
				</h2>
				<ul className="list-disc space-y-3 pl-6">
					<li className="leading-relaxed">
						De kwaliteit van de zonne-energieprojecten is verder
						verbeterd. Meest recente projecten doen het beter dan de
						projecten uit de beginjaren.
					</li>
					<li className="leading-relaxed">
						De aannames in de oorspronkelijk businesscase en de
						cijfers in het Informatie Memorandum van de productie
						verwachtingen zijn conservatief.
					</li>
					<li className="leading-relaxed">
						Sommige projecten hebben vertraging gedurende de
						installatieperiode; de impact op het productietotaal
						over de looptijd van het project is nihil.
					</li>
					<li className="leading-relaxed">
						1 project is geherstructureerd na faillissement van de
						projecteigenaar, maar met nog steeds een positieve
						rendementsverwachting: Zonnedak Ketelaars met een omvang
						van 100.000 euro is voortgezet als Zonnedak Middelweg,
						waarbij de oorspronkelijke IRR over de looptijd verlaagd
						is van gemiddeld 3,7% per jaar, naar gemiddeld 2,2% per
						jaar.
					</li>
					<li className="leading-relaxed">
						Het project Zonnedak Uit Je Eigen Stad is afgerond en
						heeft een netto resultaat van 0% voor de investeerders.
						Dit na goedkeuring door een vergadering van
						obligatiehouders. Door het terugtrekken van de garantie
						van de gemeente heeft er niet een volledige aflossing
						kunnen plaatsvinden.
					</li>
					<li className="leading-relaxed">
						Meerdere projecten hebben na goedkeuring van vergadering
						van obligatiehouders een andere rentestructuur gekregen
						(van variabel naar vaste rente); Zonnedak B4 Agro,
						Zonnedak 't Kampje, Zonnedak Molenhof, Zonnedak
						Bunninchem, Zonnedak Buitenzorg,
					</li>
					<li className="leading-relaxed">
						Alle projecten produceren zonne-energie. Er zijn geen
						'defaults' van projecten, wel (Zonne-)Rentebetalingen
						die op zich laten wachten. Zoals te zien is in de
						laatste kolom van de tabel.
					</li>
					<li className="leading-relaxed">
						Er zijn reeds 14 zonne-energieprojecten volledig
						afbetaald, de getoonde rendementen zijn iets hoger dan
						getoond, doordat afbetaling vaak niet op het einde van
						de rente periode is uitbetaald, maar eerder in het jaar.
					</li>
					<li className="leading-relaxed">
						De getoonde rendementen zijn exclusief de eventuele
						bonus van de samenwerkende energieleverancier. Het
						gemiddelde rendement is voor de investeerder hoger.
					</li>
					<li className="leading-relaxed">
						De prestaties van de individuele zonne-energieprojecten
						zijn in te zien door te klikken op de naam van het
						project.
					</li>
				</ul>
			</section>

			<section className="mb-8">
				<h2 className="mb-4 text-2xl font-semibold">
					Uitleg van termen
				</h2>
				<dl className="space-y-4">
					<div>
						<dt className="mb-2 mt-4 font-semibold">
							Huidig verwacht IRR:
						</dt>
						<dd className="ml-4 leading-relaxed">
							De huidige verwachting van het gemiddelde rendement
							per jaar over de gehele looptijd van de Obligaties.
							Op basis van (1) reeds uitgekeerde ZonneRentes, (2)
							huidige energieprijzen en (3) verwachte
							productiecijfers voor de toekomstige jaren komen we
							tot dit cijfer.
						</dd>
					</div>

					<div>
						<dt className="mb-2 mt-4 font-semibold">
							Project partners:
						</dt>
						<dd className="ml-4 leading-relaxed">
							De partners die direct zijn aangesloten op onze
							software, te weten: projecteigenaren, installateurs,
							externe financiers en energieleveanciers.
						</dd>
					</div>

					<div>
						<dt className="mb-2 mt-4 font-semibold">
							Financiering door ZonneDelers:
						</dt>
						<dd className="ml-4 leading-relaxed">
							Het totaal bedrag aan (zon-)obligaties dat door de
							projecten succesvol is gefinancierd. Naast dit
							bedrag verzorgt Zonhub ook bancaire leningen via het
							Zonfonds en financieringen via Zonhub lease.
						</dd>
					</div>

					<div>
						<dt className="mb-2 mt-4 font-semibold">
							∆ Productie t.o.v. verwachting (IM):
						</dt>
						<dd className="ml-4 leading-relaxed">
							Het verschil in totale stroomproductie vanaf start
							looptijd tot nu toe, ten opzichte van de verwachting
							zoals deze is opgenomen in de business case voor
							dezelfde periode, zoals vermeldt in het Informatie
							Memorandum, bij de start van het project.
						</dd>
					</div>

					<div>
						<dt className="mb-2 mt-4 font-semibold">
							∆ Huidige stroomprijs t.o.v. uitgangswaarde (IM):
						</dt>
						<dd className="ml-4 leading-relaxed">
							Het verschil in huidige stroomprijs ten opzichte van
							de uitgangswaarde voor de stroomprijs, zoals deze is
							opgenomen in de business case en vermeldt in het
							Informatie Memorandum. De huidige energieprijs is
							van invloed op de rendementsberekeningen voor de
							toekomstige ZonneRente-periodes.
						</dd>
					</div>

					<div>
						<dt className="mb-2 mt-4 font-semibold">
							Voldoet aan verplichtingen:
						</dt>
						<dd className="ml-4 leading-relaxed">
							De zonne-energieprojecten die regulier de ZonneRente
							uitbetalingen hebben voldaan. 'Nee' wordt ingevuld
							op het moment dat een ZonneRente-betaling meer dan
							45 dagen achterloopt.
						</dd>
					</div>
				</dl>
			</section>

			<footer className="mt-8 italic text-gray-600">
				Laatste update: 20 januari 2025
			</footer>
		</Card>
	)
}
