// React
import { useEffect, useState, useMemo, Fragment } from "react"

// Router
import { Routes } from "@/constants/routes"
import { Link } from "@/lib/router"

// Utils
import { DateTime } from "@/lib/dates"
import { motion } from "@/lib/animations"
import { emptyValue } from "@/utils/helpers"

// UI
import { classNames } from "@/lib/ui"
import { Card, CardBody, CardWrapper } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { TableEmptyState } from "@/components/table-controls/TableEmptyState"
import { Tooltip } from "@/components/Tooltip"

// Translations
import { Trans, useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// GraphQL
import {
	useInterestPaymentsQuery,
	PayOutState,
	InterestPaymentType,
	ProjectTypeEnum,
	PaymentStateEnum,
	PaymentEntryType,
	ProjectAmortizationScheduleEnum,
} from "@/api/graphql"

// Hooks
import { useCurrentProjectId } from "../hooks/useCurrentProjectId"
import { useCurrentProject } from "../hooks"

// Dates
import { dateFormat } from "@/constants/constants"

// Tables
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
	CellContext,
	createColumnHelper,
} from "@/lib/table"
import {
	TableBody,
	TableDataCell,
	TableHead,
	TableRowCell,
	TableHeading,
} from "@/components/table-controls/TableItems"

// Icons
import { FiHash, FiInfo } from "@/lib/icons"

/**
 * Extend PaymentEntryType with calculated fields
 * TODO: These can be provided by the backend
 */
export type InterestPaymentTypeExtended = InterestPaymentType & {
	payment_deadline_date: DateTime
}

/**
 * ProjectRevenue page
 * @returns
 */
export const ProjectRevenue = () => {
	const { formatNumber, formatCurrency } = useLang()
	const t = useTrans(["project", "common"])
	const id = useCurrentProjectId()

	// State
	const [
		interestPaymentHasUnpaidPayments,
		setInterestPaymentHasUnpaidPayments,
	] = useState<boolean>(false)

	// Queries
	const { data: currentProject } = useCurrentProject()
	const { data } = useInterestPaymentsQuery({
		id: String(id),
		project_id: String(id),
	})

	// Determine if we have some invalid payments
	useEffect(() => {
		const hasUnpaidPayments = data?.me?.interest_payments?.some(
			(interestPayment) =>
				interestPayment?.payment_entries?.some(
					(element) => element?.state !== PayOutState.PayoutCompleted, // TODO: Change this logic
				),
		)

		if (hasUnpaidPayments) {
			setInterestPaymentHasUnpaidPayments(true)
		}
	}, [data?.me?.interest_payments])

	// Memo interest_payments
	const interestPayments = useMemo(() => {
		return data?.me?.interest_payments || []
	}, [data?.me?.interest_payments])

	// Table Data
	const columnHelper = createColumnHelper<InterestPaymentType>()
	const columns = useMemo(
		() => [
			columnHelper.accessor((_row, rowIndex) => rowIndex + 1, {
				id: "interest_period",
				header: () => (
					<TableHeading variant="static" className="w-10">
						{t(
							"project:project.revenue.table.headers.interest_period",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<InterestPaymentType, number>) => (
					<TableDataCell className="w-10 font-medium">
						{info.getValue()}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("transaction_id", {
				header: () => (
					<TableHeading variant="static">
						{t(
							"project:project.revenue.table.headers.transaction_id",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<InterestPaymentType, string>) => (
					<TableDataCell
						fieldName={t(
							"project:project.revenue.table.headers.transaction_id",
						)}
					>
						<span className="flex flex-row">
							<FiHash
								className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
								aria-hidden="true"
							/>
							{info.getValue()}
						</span>
					</TableDataCell>
				),
			}),
			columnHelper.accessor(
				(data: InterestPaymentType) =>
					DateTime.fromISO(data?.interestperiod?.end),
				{
					id: "date",
					header: () => (
						<TableHeading variant="static">
							{t("project:project.revenue.table.headers.date")}
						</TableHeading>
					),
					cell: (
						info: CellContext<InterestPaymentType, DateTime>,
					) => (
						<TableDataCell>
							{info.getValue()
								? info.getValue().toFormat(dateFormat)
								: emptyValue()}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor((data: InterestPaymentType) => data, {
				id: "payment_deadline_date",
				header: () => (
					<TableHeading variant="static">
						{t(
							"project:project.revenue.table.headers.payment_deadline_date",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<InterestPaymentType, InterestPaymentType>,
				) => {
					const payment = info
						.getValue()
						?.payment_entries?.find(
							(element) => element !== undefined,
						)

					return (
						<TableDataCell>
							<span
								className={classNames(
									payment?.payment_state ===
										PaymentStateEnum.Done &&
										"text-green-500",
									payment?.payment_state !==
										PaymentStateEnum.Done &&
										DateTime.fromISO(
											info.getValue()?.interestperiod
												?.payment_deadline_date,
										) < DateTime.now() &&
										"text-red-500",
								)}
							>
								{DateTime.fromISO(
									info.getValue()?.interestperiod
										?.payment_deadline_date,
								).toFormat(dateFormat) || emptyValue()}
							</span>
						</TableDataCell>
					)
				},
			}),
			columnHelper.accessor(
				(data: InterestPaymentType) =>
					data?.payment_entries?.find(
						(element) => element !== undefined,
					)?.state,
				{
					id: "payment_entry.state",
					header: () => (
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.payout_state",
							)}
						</TableHeading>
					),
					cell: (
						info: CellContext<InterestPaymentType, PayOutState>,
					) => (
						<TableDataCell>
							<Tooltip
								content={
									info.getValue()
										? t(
												`project:project.revenue.payment_entry_states.${info.getValue()}.tooltip`,
										  )
										: t(
												"project:project.revenue.payment_entry_states.unknown.tooltip",
										  )
								}
							>
								<div className="flex cursor-pointer items-center">
									<FiInfo className="mr-1" />
									{info.getValue()
										? t(
												`project:project.revenue.payment_entry_states.${info.getValue()}`,
										  )
										: t(
												`project:project.revenue.payment_entry_states.unknown`,
										  )}
								</div>
							</Tooltip>
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: InterestPaymentType) =>
					data?.payment_entries?.find(
						(element) => element !== undefined,
					)?.share_count,
				{
					id: "share_count",
					header: () => (
						<TableHeading variant="static">
							{currentProject?.project?.type ===
							ProjectTypeEnum.Portfolio
								? t(
										"project:project.revenue.table.headers.solar_bonds",
								  )
								: t(
										"project:project.revenue.table.headers.solar_shares",
								  )}
						</TableHeading>
					),
					cell: (info: CellContext<InterestPaymentType, number>) => (
						<TableDataCell>
							{info.getValue()
								? formatNumber(info.getValue())
								: emptyValue()}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: InterestPaymentType) =>
					data?.payment_entries?.find(
						(element) => element !== undefined,
					)?.cost,
				{
					id: "interest",
					header: () => (
						<TableHeading variant="static">
							{t(
								"project:project.revenue.table.headers.interest",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<InterestPaymentType, number>) => (
						<TableDataCell>
							{info.getValue()
								? formatCurrency(info.getValue())
								: emptyValue()}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: InterestPaymentType) =>
					data?.payment_entries?.find(
						(element) => element !== undefined,
					)?.amortization,
				{
					id: "amortization",
					header: () => (
						<TableHeading variant="static">
							{t(
								"project:project.revenue.table.headers.amortization",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<InterestPaymentType, number>) => (
						<TableDataCell>
							{info.getValue()
								? formatCurrency(info.getValue())
								: emptyValue()}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: InterestPaymentType) =>
					data?.payment_entries?.find(
						(element) => element !== undefined,
					)?.repayment,
				{
					id: "compensation",
					header: () => (
						<TableHeading variant="static">
							{t(
								"project:project.revenue.table.headers.compensation",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<InterestPaymentType, number>) => (
						<TableDataCell>
							{info.getValue()
								? formatCurrency(info.getValue())
								: emptyValue()}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: InterestPaymentType) =>
					data?.payment_entries?.find(
						(element) => element !== undefined,
					),
				{
					id: "payment",
					header: () => (
						<TableHeading variant="static">
							{t("project:project.revenue.table.headers.payment")}
						</TableHeading>
					),
					cell: (
						info: CellContext<
							InterestPaymentType,
							PaymentEntryType
						>,
					) => {
						// Calculate sum
						const paymentSum =
							Number(info.getValue()?.cost) +
							Number(info.getValue()?.amortization) +
							Number(info.getValue()?.repayment)

						// Table data
						return (
							<TableDataCell>
								{info.getValue()
									? formatCurrency(paymentSum)
									: emptyValue()}
							</TableDataCell>
						)
					},
				},
			),
			columnHelper.accessor(
				(data: InterestPaymentType) =>
					data?.project?.amortization_schedule,
				{
					id: "project.amortization_schedule",
					header: () => (
						<TableHeading variant="static">
							{t(
								"project:project.revenue.table.headers.project_amortization_schedule",
							)}
						</TableHeading>
					),
					cell: (
						info: CellContext<
							InterestPaymentType,
							ProjectAmortizationScheduleEnum
						>,
					) => (
						<TableDataCell>
							{t(
								`common:common.project.amortization_schedule.${info.getValue()}`,
							)}
						</TableDataCell>
					),
				},
			),
		],
		[],
	)
	const table = useReactTable({
		columns,
		data: (interestPayments as InterestPaymentType[]) || [],

		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	})

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("project:project.revenue.page_title", {
						projectName: currentProject?.project?.name,
					}),
				})}
			</title>
			<div className="space-y-6">
				<Card className="xl:w-[60%]">
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("project:project.revenue.heading")}
					</Heading>
					<p className="mb-2 whitespace-pre-wrap text-sm text-gray-500">
						{t(`project:project.revenue.copy`, {
							projectName: currentProject?.project?.name,
						})}
					</p>

					{/** Bullet points */}
					<ul className="ml-0 list-disc whitespace-pre-wrap text-sm text-gray-500">
						{currentProject?.project?.supplier && (
							<li>
								{t(
									"project:project.revenue.copy.point.supplier",
								)}
							</li>
						)}
						<li>{t(`project:project.revenue.copy.point.date`)}</li>
						<li>
							<Trans
								ns="project"
								i18nKey="project.revenue.cta"
								components={{
									Link: (
										<Link
											to={`${Routes.StatisticsPlatformSingleProject}/${currentProject?.project?.id}`}
											className="text-secondary-300 hover:text-secondary-700 font-medium"
											rel="noreferrer"
										/>
									),
								}}
							/>
						</li>
						{interestPaymentHasUnpaidPayments === true && (
							<li>
								{t(
									`project:project.revenue.copy.point.unpaid-payments`,
								)}
							</li>
						)}
					</ul>
				</Card>
				<CardWrapper className="overflow-hidden border-b border-gray-200">
					<div className="overflow-x-auto overflow-y-hidden">
						<motion.table className="min-w-full divide-y divide-gray-200">
							<TableHead>
								{table.getHeaderGroups().map((headerGroup) => (
									<TableRowCell key={headerGroup.id}>
										{headerGroup.headers.map((header) => {
											return (
												<Fragment key={header.id}>
													{flexRender(
														header.column.columnDef
															.header,
														header.getContext(),
													)}
												</Fragment>
											)
										})}
									</TableRowCell>
								))}
							</TableHead>
							<TableBody
								data-testid="tablebody-overview"
								data-pageindex={
									table.getState().pagination.pageIndex
								}
							>
								{table.getRowModel().rows.map((row) => {
									return (
										<TableRowCell
											key={row.id}
											isOdd={row.index % 2 === 0}
											data-testid={`item-${DateTime.fromISO(
												row.original?.interestperiod
													?.end,
											)}${row.index}`}
										>
											{row.getAllCells().map((cell) => {
												return (
													<Fragment
														key={cell.column.id}
													>
														{flexRender(
															cell.column
																.columnDef.cell,
															cell.getContext(),
														)}
													</Fragment>
												)
											})}
										</TableRowCell>
									)
								})}
								{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
								{table.getRowModel().rows.length <
									table.getState().pagination.pageSize &&
								table.getState().pagination.pageIndex !== 0 ? (
									<>
										{Array(
											Math.max(
												table.getState().pagination
													.pageSize -
													table.getRowModel().rows
														.length,
												1,
											),
										)
											.fill(true)
											.map((_, index) => (
												<TableRowCell
													key={index}
													withHover={false}
													isOdd={index % 2 === 0}
												>
													<TableDataCell
														colSpan={columns.length}
													>
														<span className="dummy-text" />
													</TableDataCell>
												</TableRowCell>
											))}
									</>
								) : null}
							</TableBody>
						</motion.table>
					</div>
					{data?.me?.interest_payments?.length === 0 && (
						<CardBody>
							<TableEmptyState>
								{t(
									"project:project.revenue.table.empty_state",
									{
										date: DateTime.fromISO(
											currentProject?.project
												?.current_interest_period
												?.payment_deadline_date,
										).toFormat(dateFormat),
									},
								)}
							</TableEmptyState>
						</CardBody>
					)}
				</CardWrapper>
			</div>
		</>
	)
}
